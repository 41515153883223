export default defineAppConfig({
  saasName: 'Devmunity',
  customSaas: true,
  languageStrings: {
    company: 'Empresa',
    companies: 'Empresas',
    briefing: {
      visibility: 'Si el briefing se pone en "oculto" únicamente lo visualizarán los usuarios admin, y en "visible" todos los usuarios de cualquier departamento.'
    }
  },
  analyticsLooker: '972e944d-15bf-46a2-a39f-98d188480f26',
  adify: {
    actions: ['Aumentar', 'Reducir', 'Pausar', 'Mantener', 'Revisar'],
    platforms: ['Google Ads', 'Facebook Ads'],
    vertical: false,
    country: false
  },
  savvywords: {
    customers: [
      {
        name: 'Sevdesk',
        code: 'devmunity-sevdesk',
        savvywordsDataset: 'SEVDESK_SAVVYWORDS',
        googleadsDataset: 'SEVDESK_GOOGLE_ADS',
        searchtermsBucket: 'keywords_analysis_sevdesk'
      }
    ]
  },
  insights: {
    filterByCompany: false,
    filterByPlatform: false
  },
  meta: {
    adAccounts: [
      {
        name: 'Redes Lesap (116508232151666)',
        act: 116508232151666
      }
    ]
  }
})